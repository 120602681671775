<script setup lang="ts">
import '../etc/version';

import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import PopupModal from './ui/headlessui/PopupModal.vue';
import SimpleButton from './ui/SimpleButton.vue';

const reloading = ref(false);
const showPopup = computed(() => usePage().props.showVersionMessage);

const reloadPage = () => {
    reloading.value = true;
    window.location.reload();
};
</script>

<template>
    <PopupModal v-if="showPopup" 
                :title="$t('wrong_app_version.title')"
                size="max-w-xl"
                :disable="true"
                :close-on-click="false"
    >
        <p class="text-gray-500 mt-4">
            {{ $t('wrong_app_version.description')}}
        </p>

        <div class="flex justify-end mt-10">
            <SimpleButton  
                @click="reloadPage()"
                type="success"
                icon="ArrowPath"
                :loading="reloading"
            > 
                {{ $t('wrong_app_version.button')}}
            </SimpleButton>
        </div>
    </PopupModal>
</template>