import type { Coordinates } from "@/types/coordinates.types";
import type { Wall as WallType } from "@/types/room.types";
import { SATVector } from "detect-collisions";

export class Wall implements WallType {
    id: number;
    from: Coordinates;
    to: Coordinates;
    free: boolean;
    height: number;
    closestDistance?: number;

    constructor(wallData: WallType) {
        Object.assign(this, wallData);
    }

    // Calculate the length of the wall
    getLength(): number {
        const directionVector = this.getWallDirection();

        return Math.sqrt(directionVector.x * directionVector.x + directionVector.y * directionVector.y);
    }

    // Calculate the center of the wall
    getCenter(): Coordinates {
        const centerX = (this.from.x + this.to.x) / 2;
        const centerY = (this.from.y + this.to.y) / 2;
        return { x: centerX, y: centerY };
    }

    // Calculate the angle of the wall in degrees
    getAngle(): number {
        return this.getAngleRadians() * (180 / Math.PI);
    }

    // Calculate the angle of the wall in radians
    getAngleRadians(): number {
        const directionVector = this.getWallDirection();
    
        // Calculate the angle in radians
        return Math.atan2(directionVector.y, directionVector.x)
    }

    /**
     * Calculate the closest point on a line segment to a given point.
     * We limit the projection to the wall, 
     * with the parameter we can allow the function to return the closest point on the line extending infinitely in both directions. 
     */
    getClosestPointOnLine(coordinates: Coordinates, limitProjectionToWall = true): Coordinates {
        const start = this.from;
        const end = this.to;

        // Calculate the distance from the point to the wall
        const length = Math.sqrt((end.x - start.x) * (end.x - start.x) + (end.y - start.y) * (end.y - start.y));
        const dot = (((coordinates.x - start.x) * (end.x - start.x)) + ((coordinates.y - start.y) * (end.y - start.y))) / Math.pow(length, 2);

        let closestX = start.x + (dot * (end.x - start.x));
        let closestY = start.y + (dot * (end.y - start.y));

        if(limitProjectionToWall){
            if (dot < 0) {
                closestX = start.x;
                closestY = start.y;
            } else if (dot > 1) {
                closestX = end.x;
                closestY = end.y;
            }
        }

        return { x: closestX, y: closestY };
    }

    //Get the wall direction vector
    getWallDirection(): Coordinates {
        const dx = this.to.x - this.from.x;
        const dy = this.to.y - this.from.y;

        return { x: dx, y: dy };
    }

    //Get the direction vector of a wall
    getDirectionVector(): SATVector {
        const directionVector = this.getWallDirection();

        return new SATVector(directionVector.x, directionVector.y).normalize();
    }

    //Brings the direction of the wall to the inner direction (90degree rotation)
    getInnerVector(): SATVector {
        const directionVector = this.getDirectionVector();
        return new SATVector(-directionVector.y, directionVector.x).normalize();
    }
}
