import { defineStore } from 'pinia';
import { useGeneralStore } from './general';

export interface UnitCategory {
    id: string,
    title_single: string,
    title_plural: string,
    description: string,
}

interface UnitCategoryState {
  all: Record<string, UnitCategory>
  ids: string[]
}

export const useUnitStore = defineStore({
    id: 'units',

    state: (): UnitCategoryState => ({
        all: {},
        ids: []
    }),

    getters: {
        selectableProjects () {
            const generalStore = useGeneralStore();

            return generalStore.client.projects.reduce((acc, project) => {
                acc[project.id] = project.name;
                return acc;
            }, {});
        },
        selectableUnits: (state) => {
            return (project_id) => {
                if (!project_id) {
                    return [];
                }

                const generalStore = useGeneralStore();

                return generalStore.client.projects.find(project => project.id == project_id)
                    .units.reduce((acc, unit) => {
                        acc[unit.id] = unit.number;
                        return acc;
                    }, {});
            }
        },
        list() {
            return this.ids.map(id => this.all[id])
        }
    },

    actions: {
        setAll(data) {
            const unit_categories: UnitCategory[] = data;

            this.$state = unit_categories.reduce<UnitCategoryState>((acc, curr) => {
                return {
                    ids: acc.ids.concat(curr.id),
                    all: { ...acc.all, [curr.id]: curr }
                }
            }, { all: {}, ids: [] })
      }
    }
})
