import * as _ from 'lodash-es';
import { defineStore } from "pinia";
import { useRoomStore } from "./rooms";
import { useGeneralStore } from "./general";
import { useNavigationStore } from "./navigation";
import { useSortingStore } from "./sorting";
import axios from 'axios';
import { toRaw } from 'vue';
import type { ProductVariant } from '@/types/productvariant.types';
import type { ProductGroup } from '@/types/productgroups.types';
import type { Zone } from '@/types/configurationmarkers.types';
import { DrawingType } from '@/types/drawinglogic.types';
import { Pagination } from '@/types/pagination';
import { useFilteringStore } from './filtering';
import { ConfigurationMarker } from '@/utils/classes/ConfigurationMarker';
import { PositioningRule } from '@/types/positioningrule.types';

const defaultState = {
    configurationMarkers: [] as ConfigurationMarker[],
    activeConfigurationMarker: null as ConfigurationMarker,
    activeProductGroup: null as ProductGroup,
    productVariants: [],
    productsLoading: false,
    pagination: null as Pagination,
    checkedProductGroupVariant: null as ProductVariant,
    markerWaitingToBeReset: null,
    changedPrices: [],
}

export const useMarkerStore = defineStore('marker', {
    state: () => ({...defaultState}),
    getters: {
        onLastPage (state) {
            return state.pagination
                && state.pagination.current_page == state.pagination.last_page;
        },
        total_price (state) {
            return state.configurationMarkers.reduce((price, configurationMarker) => {
                return price + getTotalPriceVariants(configurationMarker);
            }, 0);
        },
        primaryProductGroups (state) {
            return state.configurationMarkers.map(
                configurationMarker => configurationMarker.primaryProductGroup()
            ).filter(productGroups => productGroups !== undefined);
        },
        primaryProductGroupActive (state) {
            return state.activeProductGroup
                ? this.primaryProductGroups.includes(state.activeProductGroup)
                : false;
        },
        activePrimaryProductGroup (state) {
            if (!state.activeConfigurationMarker || !this.primaryProductGroups) {
                return null;
            }

            return this.primaryProductGroups.find(
                group => group.marker_id === state.activeConfigurationMarker.marker_id
            );
        },
        activePrimaryProductVariant (state) {
            if (!state.activeConfigurationMarker || !this.activePrimaryProductGroup) {
                return null;
            }

            return state.activeConfigurationMarker.productVariants.find(
                variant => variant.product_group_id === this.activePrimaryProductGroup.id
            );
        },
        isProductCheckedAndPrimaryProductGroupActive (state) {
            if (!state.checkedProductGroupVariant) {
                return false;
            }
            return !!(this.primaryProductGroupActive && state.checkedProductGroupVariant);
        },
        checkedVariantPositioningRules (state) {
            if (!state.checkedProductGroupVariant) {
                return [];
            }

            return this.getRelevantPositioningRules(
                state.checkedProductGroupVariant,
                this.activeConfigurationMarker.productVariants.map(variant => variant.product_group_id),
            );
        },
        originalProductGroupVariant (state) {

            if (!state.activeProductGroup || !state.activeConfigurationMarker) {
                return null;
            }

            return state.activeConfigurationMarker.originalVariant(state.activeProductGroup);
        },
        isDirty (state) {
            return this.originalProductGroupVariant?.id != state.checkedProductGroupVariant?.id;
        },  
        getConfigurationMarkerFromMarkers (state) {
            if (!state.activeConfigurationMarker) {
                return false;
            }

            return state.configurationMarkers.find((configurationMarker: { configuration_id: number }) =>
                configurationMarker.configuration_id === state.activeConfigurationMarker.configuration_id
            );
        },

        drawingLogic (state) {
            if (!state.activeConfigurationMarker) {
                return null;
            }

            return state.activeConfigurationMarker.marker.drawing_logic.toLowerCase();
        },
        isFloorActive (state) {
            return this.drawingLogic === DrawingType.FLOOR;
        },
        lightingMarkers (state) {
            return state.configurationMarkers.filter(item => item.marker.drawing_logic === DrawingType.LIGHTING);
        },
        wallMarkers (state) {
            return state.configurationMarkers.filter(item => item.marker.drawing_logic === DrawingType.WALL);
        },
        activeWallMarker (state) {
            if (!state.activeConfigurationMarker) {
                return null;
            }

            return this.wallMarkers.find(wall => wall.position_data.wall === state.activeConfigurationMarker.position_data.wall);
        },
        activeConfigurationMarkerActions (state) {
            let actions = [];

            if (!state.activeConfigurationMarker) {
                return actions;
            }

            state.activeConfigurationMarker.productVariants.forEach(variant => {
                let triggeredActions = variant.triggered_actions || [];
                let productGroup = state.activeConfigurationMarker.productGroups
                    .find(group => group.id == variant.product_group_id);

                if (!productGroup || !productGroup.actions) {
                    return;
                }

                actions = [
                    ...actions,
                    ...productGroup.actions.filter(action => triggeredActions.includes(action.id)),
                ];
            });

            return actions;
        },
    },
    actions: {
        init(){
            return this.configurationMarkers;
        },
        $reset() {
            this.configurationMarkers = [];
            this.activeConfigurationMarker = null;
            this.clearProductGroup();
        },
        total_price_productvariants(configurationMarker: ConfigurationMarker){
            return getTotalPriceVariants(configurationMarker);
        },
        setActiveConfigurationMarker(configurationMarker: ConfigurationMarker) {
            const navigationStore = useNavigationStore();

            if (this.activeConfigurationMarker && this.activeConfigurationMarker.configuration_id === configurationMarker.configuration_id) {
                return;
            }

            this.activeConfigurationMarker = configurationMarker;
            navigationStore.setStep(this.drawingLogic);
        },
        removeConfigurationMarker(configurationMarker: ConfigurationMarker) {
            this.configurationMarkers = this.configurationMarkers.filter(m => m !== configurationMarker);
            this.activeConfigurationMarker = null;
        },
        addConfigurationMarker(configurationMarker: ConfigurationMarker) {
            this.configurationMarkers.push(configurationMarker);
        },
        removeProductVariant(productVariantId: number) {
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.productVariants = configurationMarker.productVariants.filter(item => item.id !== productVariantId);
        },
        addProductVariant(productVariant: ProductVariant) {
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.productVariants.push(productVariant);
        },
        getConfigurationMarkerById(configurationId: number) {
            const configurationMarkerIndex = this.configurationMarkers.findIndex(configurationMarker => configurationMarker.configuration_id === configurationId);
            if (configurationMarkerIndex >= 0) {
                return this.configurationMarkers[configurationMarkerIndex];
            }
            return undefined;
        },
        getConfigurationMarkerByProductGroupId(productGroupId: number): ConfigurationMarker {
            return this.configurationMarkers.find((configurationMarker: ConfigurationMarker) =>
                configurationMarker.productGroups.find(productGroup => productGroup.id === productGroupId )
            );
        },
        selectProductGroupVariant(variant: ProductVariant){
            variant.positioning_rule_id = this.getSimilarPositioningRule(
                this.getRelevantPositioningRules(
                    variant,
                    this.activeConfigurationMarker.productVariants.map(variant => variant.product_group_id),
                ),
                this.checkedProductGroupVariant?.positioning_rules?.find(
                    (rule: { id: any; }) => rule.id === this.checkedProductGroupVariant.positioning_rule_id
                ),
            )?.id || null;

            this.checkedProductGroupVariant = variant;

            this.updateVariantQuantity(
                this.activeConfigurationMarker,
                this.checkedProductGroupVariant,
            );
        },
        // Retrieve a list of positioning rules from a variant that are relevant in the
        // context of the given list of product group ids.
        getRelevantPositioningRules(variant: ProductVariant, productGroups: Array<Number>)
        {
            let positioningRules = _.orderBy(variant.positioning_rules, ['priority']);

            return positioningRules.filter(rule => {
                if (!rule) {
                    return false;
                }

                return !rule.product_group_id || productGroups.includes(rule.product_group_id);
            });
        },
        changeMarkerEncaseMaterial(option: string){
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.position_data.encasement_material = option;
        },
        changeMarkerPostionData(data){
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.position_data.x = data.x;
            configurationMarker.position_data.y = data.y;
            configurationMarker.position_data.wall = data.wall;
            configurationMarker.position_data.side = data.side;
        },
        changeMarkerRotation(rotation){
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.position_data.rotation = rotation;
        },
        editProductGroup(productGroup: ProductGroup, configurationMarker: ConfigurationMarker){

            this.clearProducts();

            const generalStore = useGeneralStore();

            generalStore.state = "choose-product";

            this.activeConfigurationMarker = configurationMarker;
            this.activeProductGroup = productGroup;

            this.loadProducts();
        },
        clearProductGroup () {
            this.activeProductGroup = null;
            this.checkedProductGroupVariant = null;
            this.clearProducts();
        },
        clearProducts() {
            this.pagination = null;
            this.productVariants = [];
            this.productsLoading = false;
        },
        loadProducts () {

            // If we're already loading products, or we're on the last page
            // of results for the current query, cancel the request.
            if (this.productsLoading || this.onLastPage) {
                return;
            }

            this.productsLoading = true;

            let request = this.pagination
                ? axios.get(this.pagination.next_page_url)
                : this.newProductVariantRequest()

            request
                .then(response => response.data)
                .then(data => {
                    this.productVariants.push(...data.data);
                    this.pagination = data.pagination;
                    this.productsLoading = false;

                    if (data.facets && data.facets.length) {
                        const FilteringStore = useFilteringStore();
                        FilteringStore.setFacetData(
                            this.activeProductGroup.id,
                            data.facets,
                        );
                    }
            });
        },
        newProductVariantRequest()
        {
            let filters = [];

            const sortingStore = useSortingStore();
            let sort = sortingStore.getSortingForProductGroup(this.activeProductGroup.id);

            const filteringStore = useFilteringStore();

            filters.push(...filteringStore.getFilterQueriesForProductGroup(this.activeProductGroup.id).flat());

            this.activeConfigurationMarkerActions.filter(action => {
                return action.target_id == this.activeProductGroup.id
                    && action.action.startsWith('filter-');
            }).forEach(action => {
                let source = this.activeConfigurationMarker.productVariants.find((variant: { product_group_id: any; }) => variant.product_group_id == action.source_id);

                if (!source) {
                    // We should trigger a warning in our error reporting system here!
                    return;
                }

                filters.push({
                    'type': action.action.split('filter-')[1],
                    'query': toRaw(action.action_data),
                    'source': source.id,
                });
            });

            return axios.get(route('client.markers.product-group-variants', {
                productGroup: this.activeProductGroup.id,
                configurationmarker: this.activeConfigurationMarker.configuration_id,
                filter: btoa(JSON.stringify(filters)),
                ...(!!sort ? {sort} : {}),
                page: this.pagination ? this.pagination.current_page + 1 : 1,
            }))
        },
        getZoneSquareMetersWithCutoff(zones: Zone[]){
            const generalStore = useGeneralStore();
            const cutoff = generalStore.cutoffPercentage;

            let totalSquareMetersZone = (zones || []).reduce((total, zone) => {
                const width = zone.to.x - zone.from.x;
                const height = zone.to.y - zone.from.y;
                const area = width * height;
                return total + area;
            }, 0) ?? 0;

            if (cutoff > 0) {
                totalSquareMetersZone *= cutoff;
            }

            return totalSquareMetersZone /= 10000;
        },
        request2dMarkerReset(configurationMarker: ConfigurationMarker, variant: ProductVariant) {
            this.markerWaitingToBeReset = {
                configurationMarker,
                variant,
            }
        },
        updateVariantQuantity(
            configurationMarker: ConfigurationMarker,
            variant: ProductVariant
        ) {
            if (variant.pack_surface) {
                variant.quantity = this.calculatePackageQuantity(configurationMarker, variant);
            } else {
                variant.quantity = variant.positioning_rules.find(rule => rule.id == variant.positioning_rule_id)?.object_multiplier || 1;
            }

            this.updateVariantCalculatedPrice(variant);
        },
        recalculateAllPrices () {
            this.configurationMarkers.forEach(configurationMarker => {
                configurationMarker.productVariants.forEach(variant => {
                    this.updateVariantCalculatedPrice(variant);
                });
            });
        },
        updateVariantCalculatedPrice (variant: ProductVariant) {
            const generalStore = useGeneralStore();

            const priceProperty = generalStore.active_vat_setting === 'inc_renovation'
            ? 'price_inc_renovation'
            : 'price_inc_new';

            variant.calculated_price = variant[priceProperty].amount * variant.quantity;
        },
        calculatePackageQuantity(
            configurationMarker: ConfigurationMarker,
            variant: ProductVariant
        ) {
            const roomStore = useRoomStore();
            const drawing_logic = configurationMarker.marker.drawing_logic.toLowerCase();

            if (variant.pack_surface <= 0 || !Number.isFinite(variant.pack_surface)) {
                return 0;
            }

            let squareMeters = 0;

            if (drawing_logic === DrawingType.FLOOR) {
                squareMeters = roomStore.square_meters_with_cutoff;
            } else if (drawing_logic === DrawingType.WALL) {
                squareMeters = roomStore.getWallSquareMetersWithCutoff(configurationMarker.position_data.wall);
            }else{
                squareMeters = this.getZoneSquareMetersWithCutoff(configurationMarker.zones);
            }

            return Math.ceil(squareMeters / variant.pack_surface);
        },
        detectChangedPrices () {
            const generalStore = useGeneralStore();

            this.changedPrices = this.configurationMarkers.reduce((accumulator, currentValue) => {
                return accumulator.concat(currentValue.getChangedPrices(generalStore.active_vat_setting, generalStore.currency));
            }, []);
        },
        clearChangedPrices () {
            this.changedPrices = [];
        },
        getSimilarPositioningRule(rules: Array<PositioningRule>, compareAgainst: PositioningRule) {
            const propertiesToCompare = [
                'bounding_box_x_position',
                'bounding_box_y_position',
                'bounding_box_z_position',
                'dummy',
                'offset_x',
                'offset_y',
                'offset_z',
                'rotation',
                'object_multiplier',
            ];

            let positioningRule = null;

            if(!!compareAgainst){
                positioningRule = rules
                    .find(rule => {
                        return propertiesToCompare.every(prop => rule[prop] === compareAgainst[prop])
                    });
            }

            if (!positioningRule) {
                // Set the positioning rule to the first available positioning rule for the selected variant.
                positioningRule = rules[0];
            }

            return positioningRule;
        }
    }
});

function getTotalPriceVariants(configurationMarker: ConfigurationMarker)
{
    return configurationMarker.getTotalCost();
}
