import { defineStore } from "pinia";
import { useGeneralStore } from "./general";
import _ from "lodash";

const defaultItems = {
    // room: ["draw", "add walls"],
    carpentry: ["door", "window"],
    cladding: [
        "floor",
        "wall",
        "ceiling"
    ],
    lighting: ["lighting"],
    furnishing: [
        "furniture",
         "accessories"
    ],
    // overview: [""],
};


export const useNavigationStore = defineStore({
    id: "navigation",

    state: () => ({
        rawItems: defaultItems,
        // currentCategory: 'carpentry',
        currentStep: 'door',
    }),

    getters: {
        items: (state) => {
            const generalStore = useGeneralStore();
            const markers = generalStore.clientMarkers;

            if(!markers){
                return {};
            }

            //Filter the navigation so only the items are left where there are markers with that drawing_logic
            return Object.entries(state.rawItems).reduce((filteredItems, [category, subItems]) => {
                const filteredSubItems = subItems.filter((item) =>
                    markers.some((marker) => marker.drawing_logic === item)
                );

                if (filteredSubItems.length > 0) {
                    filteredItems[category] = filteredSubItems;
                }

                return filteredItems;
              }, {});
            // return state.rawItems;
        },
        navigationItems () {
            return Object.values(this.items).flat();
        },
        selectedCategoryItem: (state) => {
            return _.findKey(state.rawItems, (value) => value.includes(state.currentStep));
        },
        selectedCategoryIndex () {
            const index = Object.keys(this.items).indexOf(this.selectedCategoryItem);
            return index !== -1 ? index : 0;
        },
        selectedItemIndex () {
            const index = this.navigationItems.indexOf(this.currentStep);
            return index !== -1 ? index : 0;
        },
        previousCategory () {
            const previousCategory = Object.keys(this.items)[this.selectedCategoryIndex - 1];
            return previousCategory;
        },
        nextCategory () {
            const previousCategory = Object.keys(this.items)[this.selectedCategoryIndex + 1];
            return previousCategory;
        },
        previousItem () {
            if(!this.items[this.selectedCategoryItem]){
                return
            }

            const previousItem = this.items[this.selectedCategoryItem][this.items[this.selectedCategoryItem].indexOf(this.currentStep) - 1];

            if(!previousItem) {
                const previousCategory = this.previousCategory;

                if(!previousCategory){
                    return null;
                }

                //get last item in previous category
                return this.items[previousCategory][this.items[previousCategory].length - 1];
            }

            return previousItem;
        },
        nextItem () {
            if(!this.items[this.selectedCategoryItem]){
                return
            }

            const nextItem = this.items[this.selectedCategoryItem][this.items[this.selectedCategoryItem].indexOf(this.currentStep) + 1];

            if (!nextItem) {
                if (!this.nextCategory) {
                    return null;
                }

                return this.items[this.nextCategory][0];
            }
            return nextItem;
        },
        isLastCategory () {
            return this.selectedCategoryIndex === Object.keys(this.items).length - 1;
        },
        getCurrentStep: (state) => {
            return state.currentStep.toLowerCase();
        },
    },
    actions: {
        update(payload) {
            Object.assign(this, payload);
        },
        removeItem(name) {
            const newItems = _.pick(this.rawItems, Object.keys(this.rawItems).filter(key => key !== name));

            this.rawItems = newItems;
        },
        removeItems(names) {
            const newItems = _.pick(this.rawItems, Object.keys(this.rawItems).filter(key => !names.includes(key)));

            this.rawItems = newItems;
        },
        reset(keys) {
            //Pick and choose what gets reset, if no keys provided, reset all to defaultItems
            Object.assign(this, keys?.length ? _.pick(defaultItems, keys)  : defaultItems);
        },
        setByIndex(index: number) {
            let category = Object.keys(this.items)[index];
            this.setStep(this.items[category][0]);
        },
        setStep(step: string){
            this.currentStep = step;

            const generalStore = useGeneralStore();
            generalStore.showOverview(this.selectedCategoryItem);

            return step;
        },
        // setCategory(category){
        //     this.currentCategory = category;

        //     const generalStore = useGeneralStore();
        //     generalStore.showOverview(category);

        //     return category;
        // },
    },
});
