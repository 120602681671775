export default {
    'global': {
        'roomDraw': {
            name: 'room.draw',
            transform: (event: Object) => event,
        },
        'roomClear': {
            name: 'room.clear',
            transform: (event: Object) => event,
        },
        'roomMaterialUpdate': {
            name: 'room.material.update',
            transform: (event: Object) => event,
        },
        
        'wallDraw': {
            name: 'wall.create',
            transform: (event: Object) => event,
        },
        'wallUpdate': {
            name: 'wall.update',
            transform: (event: Object) => event,
        },
        'wallDelete': {
            name: 'wall.delete',
            transform: (event: Object) => event,
        },
        
        'wallZoneCreate': {
            name: 'wall.zone.create',
            transform: (event: Object) => event,
        },
        'wallZoneUpdate': {
            name: 'wall.zone.update',
            transform: (event: Object) => event,
        },
        'wallZoneDelete': {
            name: 'wall.zone.delete',
            transform: (event: Object) => event,
        },

        'cameraZoom': {
            name: 'camera.zoom',
            transform: (event: Object) => event,
        },
        'cameraView': {
            name: 'camera.view',
            transform: (event: Object) => event,
        },
        'cameraRotate': {
            name: 'camera.orbit',
            transform: (event: Object) => event,
        },
        'cameraFocus': {
            name: 'camera.focus',
            transform: (event: Object) => event,
        },
        'cameraFocusRotate': {
            name: 'camera.focus.orbit',
            transform: (event: Object) => event,
        },
        'cameraFocusZoom': {
            name: 'camera.focus.zoom',
            transform: (event: Object) => event,
        },

        'objectCreate': {
            name: 'object.create',
            transform: (event: Object) => event,
        },
        'objectDelete': {
            name: 'object.delete',
            transform: (event: Object) => event,
        },
        'objectMove': {
            name: 'object.move',
            transform: (event: Object) => event,
        },
        'objectModelSet': {
            name: 'object.model.set',
            transform: (event: Object) => event,
        },
        'objectModelDelete': {
            name: 'object.model.delete',
            transform: (event: Object) => event,
        },
        'objectModularUpdate': {
            name: 'object.modular.update',
            transform: (event: Object) => event,
        },
        'objectModularDelete': {
            name: 'object.modular.delete',
            transform: (event: Object) => event,
        },
        'objectLightingUpdate': {
            name: 'object.lighting.update',
            transform: (event: Object) => event,
        },

        'sceneBackground': {
            name: 'scene.background',
            transform: (event: Object) => event,
        },
        'sceneSunstance': {
            name: 'scene.sunstance',
            transform: (event: Object) => event,
        },

        'screenshot': {
            name: 'screenshot',
            transform: (event: Object) => event,
        },
        'screenshotResponse': {
            name: 'screenshot.response',
            transform: (event: Object) => event,
        },

        'branding': {
            name: 'branding',
            transform: (event: Object) => event,
        },

        'sessionStart': {
            name: 'session.open',
            transform: (event: Object) => event,
        },
        'sessionStop': {
            name: 'session.close',
            transform: (event: Object) => event,
        },
        'sessionReveal': {
            name: 'session.reveal',
            transform: (event: Object) => event,
        },
    }
}
