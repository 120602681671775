if (import.meta.env.MODE !== 'development') {
    // @ts-expect-error
    import('vite/modulepreload-polyfill');
}
import '../css/client.css';
import PortalVue from 'portal-vue';
import { createApp, h, type DefineComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { ZiggyVue }  from 'ziggy-js';
import App from '@/layouts/App.vue';
import i18nInstance from './lang/i18n';
import * as _ from 'lodash-es';
import { createPinia } from 'pinia';
import Vidle from 'v-idle-3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import GlobalDynamicStyles from '@/components/ui/GlobalDynamicStyles.vue';
import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";
import posthog from "posthog-js";
import event from './events/eventBus';

if (process.env.NODE_ENV !== 'local') {
    flare.light();
}

createInertiaApp({
    resolve: async (name) => {
        const page = await resolvePageComponent(`./pages/${name}.vue`, import.meta.glob<DefineComponent>('./pages/**/*.vue', { import: 'default'}));

        if(!page.layout){
            page.layout = App;
            return page;
        }

        let filteredLayouts = _.castArray(page.layout || []).filter(item => item.name !== 'app')

        page.layout = [App, ...filteredLayouts];
        return page;
    },
    setup({ el, App, props, plugin }) {
        event.emit('sessionStop', {});
        // event.emit('sessionStart', {
        //     'force_start': true,
        // });

        const VueApp = createApp({
            render: () => h(App, props),
        });

        if (__APP_ENV__ === 'production') {
            posthog.init(__POSTHOG_KEY__, {
                api_host: __POSTHOG_HOST__,
                disable_session_recording: true,
            });
        }

        VueApp.provide("posthog", posthog);

        //Add Global components
        VueApp.component('GlobalDynamicStyles', GlobalDynamicStyles)

        VueApp
            .use(flareVue)
            .use(plugin)
            .use(ZiggyVue)
            .use(i18nInstance)
            .use(createPinia())
            .use(PortalVue)
            .use(Vidle)
            .mount(el);
    },
    progress: {
        // The delay after which the progress bar will
        // appear during navigation, in milliseconds.
        delay: 250,

        // The color of the progress bar.
        color: '#29d',

        // Whether to include the default NProgress styles.
        includeCSS: true,

        // Whether the NProgress spinner will be shown.
        showSpinner: false,
    }
});
