import { defineStore } from "pinia";
// import { formatDate } from '@/utils/dates';
import * as _ from 'lodash-es';
import type { Client } from "@/types/client.types";

interface State {
    client: Client;
    state: string;
    // showNavigation: boolean;
    currency: "EUR" | "USD";
    cutoff: number;
    loadConfiguration: boolean;
    oldConfiguration: boolean; //When we have an old configuration we use the old coordinates for placing a wall mounted object on a wall. Remove this after 1 year
    clientMarkers: any;
    use_configuration_webhook: boolean;
    environment: string;
    debugMode: boolean;
    vat_settings: ["inc_new", "inc_renovation"];
    active_vat_setting: "inc_new" | "inc_renovation";
    sunSettings: {
        time: number;
        min_time: number;
        max_time: number;
        north_offset: number;
    }
}

export const useGeneralStore = defineStore('general', {
    state: (): State => {
        return {
            client: null,
            state: 'start',
            // showNavigation: false,
            currency: 'EUR',
            cutoff: 2, //percentage how many extra tiles needed (move this to client level and do calculations in the backend, pass second parameter square_meters with cutoff)
            loadConfiguration: false,
            oldConfiguration: false, //When we have an old configuration we use the old coordinates for placing a wall mounted object on a wall. Remove this after 1 year
            clientMarkers: null,
            use_configuration_webhook: false,
            environment: __APP_ENV__,
            debugMode: false,
            vat_settings: ["inc_new", "inc_renovation"],
            active_vat_setting: 'inc_new',
            sunSettings: {
                time: 660,  //default 11:00
                min_time: 0,
                max_time: 1439,
                north_offset: 0,
            }
        }
    },
    getters: {
        isRetail: (state: State): boolean => state.client && state.client.type == 'retail',
        currencySymbol: (state: State): "€" | "$" => {
            switch (state.currency) {
                case 'EUR':
                    return '€';
                case 'USD':
                    return '$';
                default:
                    return '€';
            }
        },
        cutoffPercentage: (state: State): number => (state.cutoff / 100) + 1,
        showSkuNumbers: (state: State): boolean => state.environment !== 'production' || state.debugMode,
        configurableMarkers(state: State) {
            //ConfigurableMarkers are used in retail so the client can configure a marker
            //They also need to be grouped on markerGroup
            if (!state.clientMarkers) {
                return null;
            }

            const configurableMarkers = state.clientMarkers.filter(marker => marker.configurable);

            //We group them based on marker_group,
            //When no marker_group is present we create a custom group from the marker
            const groupedMarkers = groupBy(configurableMarkers, 'marker_group_id', 'marker_group');

            const sortedMarkers = _.orderBy(groupedMarkers, [group => group.marker_group.sort_order]);

            return sortedMarkers;
        },
        vatAmount: (state: State): 21 | 6 => state.active_vat_setting === 'inc_new' ? 21 : 6,
        sunStance(state: State): {
            date_time: string, 
            north_offset: number
        } {
            const date_time = this.formattedTime(this.sunSettings.time);
            // const date = `2024-02-06 ${date_time}:00`; //We set date in spring time (Azimuth)

            // const formattedDateTime = formatDate(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS", 'nl');

            return {
                date_time: date_time,
                north_offset: state.sunSettings.north_offset,
            }
        }
    },
    actions: {
        toggleDebugMode(): void {
            this.debugMode = !this.debugMode;
        },
        // toggleNavigation(): void {
        //    this.showNavigation = !this.showNavigation;
        // },
        showOverview(category: string): void{
            //show correct state when category is overview
            if(category === 'overview'){
                this.state = 'summary';
            }else{
                this.state = '';
            }
        },
        setCurrency(currency: State['currency']): void {
            this.currency = currency;
        },
        setClient(client: Client): void {
            this.client = client;
            this.clientMarkers = client?.clientMarkers;
            this.use_configuration_webhook = client?.use_configuration_webhook;
        },
        setLoadConfiguration(loadConfiguration: boolean): void {
            this.loadConfiguration = loadConfiguration;
        },
        setOldConfiguration(oldConfiguration: boolean): void {
            this.oldConfiguration = oldConfiguration;
        },
        switchVatSetting(setting: State['vat_settings'] | null = null): void {
            if(setting){
                this.active_vat_setting = setting;
                return
            }

            const currentIndex = this.vat_settings.findIndex(setting => setting === this.active_vat_setting);
            const nextIndex = (currentIndex + 1) % this.vat_settings.length;
            this.active_vat_setting = this.vat_settings[nextIndex];
        },
        formattedTime(time: number): string {
            const hours = Math.floor(time / 60);
            const minutes = Math.round(time % 60);

            return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
        },
    }
});

function groupBy(array, key, name) {
    let nullCounter = 0;

    return array.reduce((result, obj) => {
        const group = obj[key] ?? `null${nullCounter++}`;
        result[group] ??= {
            marker_group: obj[key] ? obj[name] : {
                icon: obj.icon,
                name: obj.name
            },
            items: []
        }
        result[group].items.push(obj);
        return result;
    }, {});
  }
